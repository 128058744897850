@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fugaz+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fugaz+One&display=swap);
button {
    padding: 0.5em 1em;
    border-radius: 2em;
    font-size: 0.8em;
    background: none;
    color: white;
    font-weight: bold;
}

.green-btn {
    background-image: var(--backgreen);
    background-size: 120% 120%;
    background-position: center;
    border: 2px solid transparent;
}

.blue-btn {
    background-color: #163E59;
    color: white;
    background-size: 120% 120%;
    background-position: center;
    border: none;
}

.valid-button {
    color: #021438;
    font-size: 0.8em;
    background-image: var(--backgreen);
    background-size: 120% 120%;
    background-position: center;
    border: 2px solid transparent;
    font-weight: bold;
}

#cta-btn {
    font-size: 1.3em;
    color: #021438;
}

.border-btn {
    /* border: 2px solid var(--lightgreen); */
    color: white;
    border: none;
    font-weight: lighter;
    font-size: 1.1em;
}

.see-more-btn {
    /* border-color: var(--darkblue); */
    color: var(--darkblue);
    line-height: 0.5em;
    font-size: 1.2em;
}

.left-btn {
    margin-left: 1em;
}

.right-btn {
    margin-right: 1em;
}

.down-btn {
    margin-bottom: 3rem;
    color: var(--lightgreen);
    font-size: 1em;
}

.up-btn {
    margin-top: 3em;
}
.icon-box{
    border: none;
}

.icon-gears{
    font-size: 3em;
}
/* /// SECTIONS */
.sim-page {
  height: 92vh;
  background-color: #e5e5e5;
  color: #163E59;
  padding: 10px;
  min-height: 500px;
}

.sim-container-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin-right: 10px;
}

.sim-results-box {
  height: 100%;
  width: calc(40% - 10px);
}

/* /// LOADER */
#loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* /// SIM-NAV */
#sim-nav-box {
  align-items: center;
  height: 130px;
  padding: 10px;
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
}


#sim-nav-box h1,
#results-top-box h1,
#results-emissions h1 {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #163e59;
  padding-bottom: 0;
}

#sim-nav-box h2 {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}

#sim-nav-box>div {
  width: 100%;
  justify-content: space-between;
  margin-top: 0.3em;
}

#sim-nav-fr {
  width: 72%;
  justify-content: space-between;
}

#sim-nav-world {
  width: 13%;
}

.sim-nav {
  width: 100%;
}

.sim-nav-categories {
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.2) 1px;
  justify-content: space-between;
  padding-top: 5px;
}

.sim-nav-category {
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  height: 45px;
}

.sim-nav-category .sim-nav-category-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
  opacity: 0;
  transition: 1s;
}

.sim-nav-category:hover .sim-nav-category-background {
  opacity: 1;
}

.sim-nav-category .sim-nav-category-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.sim-nav-category .sim-nav-category-icon-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.sim-nav-category img {
  width: 90%;
  max-width: 30px;
  display: inline-block;
  vertical-align: middle;
}

#sim-nav-fr .sim-nav-category {
  width: 16%;
}

#sim-nav-world .sim-nav-category {
  width: 95%;
}

#options {
  width: 12%;
  margin-top: 31px;
  background-color: #b0e0e6;
}


/* /// MAIN-BOX */
.sim-main-box {
  background-color: white;
  height: auto;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 140px);
  width: 100%;
}

/* /// TITRES CATEGORIES */

.sim-categorie {
  padding: 1.5% 1em;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  height: auto;
  width: 100%;
  text-align: center;
}

.sim-categorie>div {
  width: 55%;
  justify-content: space-between;
  align-items: center;
}

.sim-jauge {
  width: 100%;
  height: 20px;
  margin: 5px 0;
}

.sim-categorie-emissions {
  font-size: 15px;
  line-height: 19px;
}

.sim-categorie-markers {
  /* justify-content: space-evenly; */
  align-items: baseline;
  width: 100%;
}

.sim-categorie-markers>div {
  align-items: center;
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiTooltip-tooltipArrow {
  background-color: blue;
}

.sim-categorie-markers>div>div {
  width: 100%;
}

/* 
.sim-categorie-markers > div > div:last-of-type {
  width: 70%;
} */

.sim-categorie-markers p {
  font-size: 11px;
  text-align: center;
}

.sim-emissions-2030 {
  color: #0b8c85;
}

.sim-emissions-2020 {
  color: #ff6868;
}

.sim-emissions-2020 span {
  font-size: 9px;
}

.sim-emissions-2030 span {
  font-size: 9px;
}

.sim-categorie-name {
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #163e59;
  text-align: left;
  width: 45%;
}

/* /// OPTIONS */

#optionsContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
  z-index: 9999;
}

#optionsContainer .optionsContainerBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}

#optionsContainer .optionsContainerClose {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
}

#scrollOptions {
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  color: #163E59;
}

#scrollOptions>div {
  justify-content: center;
}

.sim-options {
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 0;
}

.sim-option-box {
  width: 90%;
  margin-bottom: 30px;

}

.sim-option-box p {
  margin-bottom: 0.5em;
  font-size: 0.8em;
}

.sim-option-box label {
  font-size: 1em;
  margin-right: 0.5em;
}

.sim-option-form {
  justify-content: flex-start;
}

.sim-option-form div {
  height: 2.2em;
  align-items: center;
  margin-right: 20px;
}



.sticky {
  margin-right: -0.3em;
  z-index: 2;
  background-color: #B0E0E6;
  position: sticky;
  position: -webkit-sticky;
  padding-bottom: 20px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  text-align: left;
  margin-top: 150px;
}

.sticky .sim-categorie {
  background: #B0E0E6;
}

.sim-param-box {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 2%;
}

.sim-param-radio {
  margin-right: 1em;
}

input[type="radio"] {
  margin-right: 1em;
}

.bigger {
  height: 2.2em;
}

/* /// JAUGES */
.jauge-ext {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.jauge-int {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* background-image:linear-gradient(80deg, #7FFFD4 0%,  #88F2B6 18%, #77D9B5 38%, #1087A1 100%); */
}

.jauge-int-max {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.marker1,
.marker2 {
  z-index: 3;
}

/* /// RESULTS */
/* /// PROJECTION MONDIALE */
#results-top-box {
  background-color: white;
  height: 180px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
}

#results-impacts-box {
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0.5em;
}

.n1 {
  grid-area: title1;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n4 {
  grid-area: title2;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n7 {
  grid-area: title3;
  text-align: center;
  align-self: center;
  margin: 0;
}

.n2 {
  grid-area: tag1;
  justify-self: center;
  margin: 0;
}

.n5 {
  grid-area: tag2;
  justify-self: center;
  margin: 0;
}

.n8 {
  grid-area: tag3;
  justify-self: center;
  margin: 0;
}

.n3 {
  grid-area: legend1;
  text-align: center;
  margin: 0;
}

.n6 {
  grid-area: legend2;
  text-align: center;
  margin: 0;
}

.n9 {
  grid-area: legend3;
  text-align: center;
  margin: 0;
}

#results-impacts-box {
  display: grid;
  width: 100%;
  margin: 0;
  grid: auto auto auto / 1fr 1fr 1fr;
  grid-gap: 2px 10px;
  gap: 2px 10px;
  grid-template-areas:
    "title1 title2 title3"
    "tag1 tag2 tag3"
    "legend1 legend2 legend3";
  margin-top: 0.5em;
}

/* /// PROJECTION FRANCAISE */

#results-bottom-box {
  background-color: white;
  height: calc(100% - 190px);
  width: 100%;
  padding: 10px;
  justify-content: space-between;
}

#results-emissions {
  width: 100%;
  align-items: center;
}

#results-emissions-charts-container {
  height: 55%;
  width: 100%;
  display: grid;
  grid: 85% 13% / 46% 46%;
  grid-gap: 5px 20px;
  gap: 5px 20px;
  grid-template-areas:
    "g1 g3"
    "g2 g4";
}

.g1 {
  grid-area: g1;
  width: 100%;
  height: 100%;
}

.g3 {
  grid-area: g3;
  width: 100%;
  height: 100%;
}

.g2 {
  grid-area: g2;
  text-align: center;
}

.g4 {
  grid-area: g4;
  text-align: center;
}

#results-emissions-charts-container p {
  text-align: center;
  font-size: 1.1em;
}

.chart {
  width: 100%;
  height: 100%;
}

#area-tooltip {
  padding: 10px;
  width: 350px;
  align-items: flex-start;
}

#area-tooltip .legend-point {
  margin-left: 20px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

#area-tooltip>div {
  align-items: center;
}

.chart-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  box-shadow: 3px 3px 3px #163E59;
  font-size: 0.8em;
}

.chart-tooltip h4 {
  font-size: 1.1em;
  align-self: center;
}

.chart-tooltip p {
  font-size: 0.9em;
  align-self: center;
}


/* /// COMPOSANT INDICATEUR AVEC TITRE BOX ET LEGENDE */
#results-impacts-box2 {
  display: grid;
  width: 100%;
  margin: 0;
  grid: auto auto auto / 1fr 1.2fr 1fr;
  grid-gap: 2px;
  gap: 2px;
  grid-template-areas:
    "title4 title5 title6"
    "tag4 tag5 tag6"
    "legend4 legend5 legend6";
  margin-top: 0.5em;
}

.b1 {
  grid-area: title4;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b4 {
  grid-area: title5;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b7 {
  grid-area: title6;
  text-align: center;
  align-self: center;
  margin: 0;
}

.b2 {
  grid-area: tag4;
  justify-self: center;
  margin: 0;
}

.b5 {
  grid-area: tag5;
  justify-self: center;
  text-align: center;
  margin: 0;
}

.b8 {
  grid-area: tag6;
  justify-self: center;
  margin: 0;
}

.b3 {
  grid-area: legend4;
  text-align: center;
  margin: 0;
}

.b6 {
  grid-area: legend5;
  text-align: center;
  margin: 0;
}

.b9 {
  grid-area: legend6;
  text-align: center;
  margin: 0;
}

.results-title {
  font-size: 0.9em;
}

.results-legend {
  font-size: 0.7em;
  padding: 0 20px;
  text-align: center;
  justify-self: center;
}

.results-figure {
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 5px;
  line-height: normal;
  width: 5em;
  height: 3rem;
}

#results-impacts-box2 .results-figure {
  font-size: 1.5rem;
  /* padding: 0.1em 1.2em; */
}

.figure-unit {
  font-size: 0.7em;
  font-weight: lighter;
  margin-top: -0.2em;
}

#results-button {
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
}

.sim-results-box button {
  padding: 0.5em 2em;
  font-size: 1.4em;
}

/* LOADER */

#sim_loader {background-color: white;opacity: 0.3;}

#sim_loader_content {
  background-color: transparent;
  box-shadow: none;}


/* /// EXPORT RESULTS */
#copy_results {display: flex;flex-wrap: wrap;justify-content: flex-start;width: 70vw;padding: 10px 50px 10px}
#copy_results h3 {margin-bottom: 10px;}
#copy_results p {text-align:left;margin-bottom:  10px;}
#copy_results button { font-weight:500;font-size: 18px;margin-bottom: 15px;margin-right: 15px;color:#163e59;border:none;transition-duration: 0.4s;}
#copy_results > div:last-of-type {margin-top: 20px}
#copy_results > div:last-of-type button { background-color: #163e59;color:white}
#copy_results button:hover {font-weight: bold};


@media screen and (max-width: 1110px) {
  body {
    font-size: 3px;
  }

  .sim-container-box {
    width: 50%;
  }

  .sim-results-box {
    width: calc(50% - 10px);
  }
}

@media screen and (min-height: 1100px) {
  #results-emissions-charts-container {
    grid: 40% 6% 40% 6% / 60%;
    grid-gap: 5%;
    gap: 5%;
    justify-content: center;
    grid-template-areas:
      "g1"
      "g2"
      "g3"
      "g4";
  }
}

@media screen and (max-height: 700px) {

  .sim-categorie-emissions {
    font-size: 11px;
  }

  #results-top-box {
    height: 150px;
  }

  #results-bottom-box {
    height: calc(100% - 150px);
  }

  #sim-nav-box h1,
  #results-top-box h1,
  #results-emissions h1 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .results-title {
    font-size: 0.8em;
  }

  .results-figure {
    font-size: 1.2em;
    width: 5em;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .b5 {
    line-height: normal;
  }

  #results-impacts-box2 .results-figure {
    font-size: 1.2rem;
  }

  .results-legend {
    font-size: 0.6em;
  }

  .b9 {
    font-size: 0.5em;
  }


  #results-emissions-charts-container {
    height: 45%;
    grid-gap: 2px 15px;
  }

  #results-emissions-charts-container p {
    font-size: 1em;
  }

  .sim-results-box button {
    padding: 0.4em 2em;
    font-size: 1.2em;
  }

}
.icon-box{
    border: none;
}

.icon-gears{
    font-size: 3em;
}
.results-page {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}



.results-page h1 {
    color: #7fffd4;
    font-size: 3em;
    border-bottom: #7fffd4 solid 4px;
    margin-bottom: 30px;
}


.results-page h2 {
    color: #7fffd4;
    font-size: 2em;
    align-self: flex-start;
}

.results-page h3 {
    font-size: 1.5em;
    align-self: flex-start;
}

.results-page .contact-white {
    width: 65%;
}

.results-page .contact-white p {
    font-size: 1.2em;
}


/* BLINKING BTN */
#blinking-results {
    position: -webkit-sticky;
    position: sticky;
    top: 2vh;
    align-self: flex-end;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
    color: var(--lightgreen);
    font-size: 4em;
    border: none;
}

/* /// HERO RESULTS */

.chapter-selection:hover span {
    color: #7fffd4;
    border-bottom: 5px solid #7fffd4;
}

.results-box {
    font-size: 1em;
    width: 80%;
    height: 70%;
    padding: 2em 3em;
    grid-template-columns: 0.8fr 0.9fr 1.3fr;
    align-items: flex-start;
    border-radius: 1em;
}

.results-left {
    margin-top: 0.3em;
    height: 95%;
    justify-content: space-between;
    align-items: flex-start;
}

.results-left h3 {
    margin-bottom: 1em;
}

.results-left h5 {
    margin-bottom: 2em;
}

#results-impacts {
    justify-content: flex-end;
}

.sim-results-head-results h5 {
    width: 100%;
    font-size: 1.5em;
    text-align: left;
}

.sim-results-head-results p {
    font-size: 1em;
}

.results-figure-results {
    background-color: var(--grey);
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 3rem;
    width: 5em;
    border-radius: 0.5rem;
    margin-bottom: 0.2rem;
    margin-right: 2em;
}

.results-data {
    margin-top: 0.3em;
    width: 90%;
    height: 90%;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em;
    padding-left: 1em;
}

.results-data p {
    height: 5%;
    text-align: center;
}

.results-data-sunburst {
    width: 100%;
    height: 100%;
}

.results-data-area {
    width: 100%;
    height: 100%;
}

.results-btns {
    width: 80%;
    justify-content: space-between;
}

.share-icon {
    height: 32px;
}

/* ////////////////////////// */
/* /// SYNTHESE */
/* ////////////////////////// */

#hero-article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


#res-synthese {
    flex: 1 1;
    margin-top: 5vh;
    width: 100%;
    align-items: center;
}

#res-synthese>div {
    width: 90%;
    padding: 20px;
    background-color: #442D59;
}

#res-synthese>div>* {
    justify-content: center;
    text-align: center;
}

#res-synthese-indicator {
    align-items: flex-start;
}

.tag-container {
    align-items: center;
    width: 30%;
}

#res-synthese>div>p {
    color: #7fffd4;
    margin: 30px 0;
    font-size: 1.5em;
    margin-left: 15%;
    width: 70%;
}

#res-synthese button {
    border: none;
    color: #34244E
}

#res-synthese-buttons>div {
    background-color: white;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    color: #34244E;
    align-items: center;
    justify-content: center;
    display: flex;
}

#res-synthese-buttons div:first-child {
    margin-right: 1em;
}


/* ////////////////////////// */
/* /// FRANCE */
/* ////////////////////////// */


/* /// PIE */
#res-emi-fr {
    width: 80%;
    align-items: center;
}

.res-emi-fr-container {
    min-height: 100vh;
    width: 100%;
    margin: 3vh 0;
}

.res-title-box {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin-bottom: 30px;
}

.chart-short-desc {
    font-size: 1.1em;
    margin-bottom: 20px;
    font-style: italic;
}

.res-chart-container {
    width: 100%;
}

.res-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    height: 75vh;
}

.res-chart img {
    max-width: 98%;
}

.res-chart-infos {
    width: 45%;
    justify-content: space-around;
    padding-left: 15px
}

.res-chart-infos p {
    font-size: 0.9em;
}

.res-chart-legend {
    flex-wrap: wrap;
}

.res-chart-legend>div {
    width: 45%;
    align-items: center;
    margin-right: 5%;
}

.res-chart-legend>div div {
    padding: 5px 10px 5px 0;
}

.res-chart-legend>p {
    margin-bottom: 10px;
}

.res-chart-legend td {
    padding: 5px 10px 5px 0;
}

.legend-point {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.res-chart-source {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
}


/* ////////////////////////// */
/* /// MONDE */
/* ////////////////////////// */

#res-emi-world {
    width: 80%;
    align-items: center;
}

/* ////////////////////////// */
/* /// IMPACTS */
/* ////////////////////////// */

#res-impacts {
    width: 80%;
    align-items: center;
}

#res-impacts .res-chart {
    width: 60%;
}

#res-impacts .res-chart-infos {
    width: 38%;
}


/* /// DETAIL RESULTS */
.detail-results {
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
}

.top-btn {
    margin-bottom: 4em;
}

.detail-national {
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin: 2em 0 3em;
    width: 90%;
}

.detail-national-main {
    width: 90%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: stretch;
    justify-items: center;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
}

.detail-national-box {
    width: 90%;
    height: 80%;
    align-items: center;
}

.detail-national-box h4 {
    margin-bottom: 1em;
    text-align: center;
}

.detail-impacts-temperature {
    align-items: center;
    width: 90%;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
}

.detail-impacts-temperature img {
    width: 70%;
    height: auto;
    margin-bottom: 3em;
}

.detail-world {
    padding: 1em;
    border-radius: 1em;
    margin: 1em 0 2em;
    width: 50%;
    height: 30em
}

.detail-world>h2 {
    text-align: center
}


.detail-parameters {
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
    width: 100%;
}

.detail-parameters-box {
    width: 90%;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
    grid-column-gap: 2em;
    -webkit-column-gap: 2em;
            column-gap: 2em;
    grid-row-gap: 1em;
    row-gap: 1em;
    border-radius: 1em;
    padding: 1em;
    margin: 1em 0 2em;
}


/* FORM SAVE */

.modal-div {
    background-color: #07163A;
}

.new-or-edit {
    background-color: #07163A;
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 1em 4em;
}

.new-or-edit>*>* {
    margin: 0 5px;
}

.form-popup {
    background-color: #07163A;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
}

.form-popup>* {
    margin-bottom: 1em;
}

.popup-error {
    background-color: #07163A;
    text-align: center;
    padding: 1em;
    color: white;
}

.popup-error a {

    font-weight: bold;
    margin-right: 0.3em;
}


/* BLINKING BTN */
.blinking {
    -webkit-animation: blinker 2s linear infinite;
            animation: blinker 2s linear infinite;
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* 

.div-linear-chart {
    height: 100%
} */

@media (max-width: 1000px) {

    .results-page h3 {
        font-size: 1.2em;
        align-self: flex-start;
    }

    .chart-short-desc {
        font-size: 0.9em;
    }

    .res-chart-infos p {
        font-size: 0.7em;
    }

}


@media (min-height: 800px) {

    .res-emi-fr-container {
        height: 800px;
        width: 100%;
    }

    .res-chart {
        height: 600px;
    }


}



@media (min-height: 1100px) {

    #hero-article {
        height: auto;
        margin-bottom: 10vh;
    }

}
@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-MediumItalic.784a62a1.eot);
    src: local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'),
        url(/static/media/CircularStd-MediumItalic.784a62a1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-MediumItalic.6da584bd.woff2) format('woff2'),
        url(/static/media/CircularStd-MediumItalic.eddb7021.woff) format('woff'),
        url(/static/media/CircularStd-MediumItalic.f31aaf0e.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-Black.b9fb083e.eot);
    src: local('Circular Std Black'), local('CircularStd-Black'),
        url(/static/media/CircularStd-Black.b9fb083e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-Black.a7d0eebb.woff2) format('woff2'),
        url(/static/media/CircularStd-Black.b5fa1b55.woff) format('woff'),
        url(/static/media/CircularStd-Black.6b37ec3a.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-Medium.35964d63.eot);
    src: local('Circular Std Medium'), local('CircularStd-Medium'),
        url(/static/media/CircularStd-Medium.35964d63.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-Medium.0afc4e78.woff2) format('woff2'),
        url(/static/media/CircularStd-Medium.e47ea4c0.woff) format('woff'),
        url(/static/media/CircularStd-Medium.e4ba63a6.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-Bold.05e42684.eot);
    src: local('Circular Std Bold'), local('CircularStd-Bold'),
        url(/static/media/CircularStd-Bold.05e42684.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-Bold.b26584c8.woff2) format('woff2'),
        url(/static/media/CircularStd-Bold.6f4fe3ca.woff) format('woff'),
        url(/static/media/CircularStd-Bold.145ae1e7.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-BlackItalic.06bbad3b.eot);
    src: local('Circular Std Black Italic'), local('CircularStd-BlackItalic'),
        url(/static/media/CircularStd-BlackItalic.06bbad3b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-BlackItalic.8f80cd37.woff2) format('woff2'),
        url(/static/media/CircularStd-BlackItalic.2153a12d.woff) format('woff'),
        url(/static/media/CircularStd-BlackItalic.02ae5721.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url(/static/media/CircularStd-BookItalic.6ba855cf.eot);
    src: local('Circular Std Book Italic'), local('CircularStd-BookItalic'),
        url(/static/media/CircularStd-BookItalic.6ba855cf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-BookItalic.8b30196c.woff2) format('woff2'),
        url(/static/media/CircularStd-BookItalic.cc06409e.woff) format('woff'),
        url(/static/media/CircularStd-BookItalic.cdf3e399.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src: url(/static/media/CircularStd-BoldItalic.b43256a8.eot);
    src: local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'),
        url(/static/media/CircularStd-BoldItalic.b43256a8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-BoldItalic.b88d7b4e.woff2) format('woff2'),
        url(/static/media/CircularStd-BoldItalic.8d5dbe81.woff) format('woff'),
        url(/static/media/CircularStd-BoldItalic.f4a95390.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url(/static/media/CircularStd-Book.60f92783.eot);
    src: local('Circular Std Book'), local('CircularStd-Book'),
        url(/static/media/CircularStd-Book.60f92783.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CircularStd-Book.35e6fb86.woff2) format('woff2'),
        url(/static/media/CircularStd-Book.c47d7e58.woff) format('woff'),
        url(/static/media/CircularStd-Book.9e311611.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}


/* < Desktop */
@media (max-width: 1280px) {}

/* < Optional ? // small desktop */
@media (max-width: 1024px) {
    body #root {
        width: 100%;
        max-width: 100%;
        min-width: 0;
        height: 100%;
        margin: 0;
        align-self: center;
    }

    /* Home Page */
    body .next-landing {
        width: 85%
    }

    /* Concept */
    body #concept .concept-img-box img {
        width: 95%;
    }

    body #enjeux .concept-img-box img {
        width: 95%;
    }


    /* A propos */
    body .about-card p {
        text-align: left;
    }

    body .dev-team {
        width: 48%;
    }

    /* Contribuer */
    body .contribuer {
        width: 85%;
    }

    body .sim-param-radio input[type="radio"] {
        margin-right: 0;
    }

    body .sim-param-radio {
        text-align: center;
        margin: 0.5em;
    }

    body .sim-param-radio .small-param-desc {
        display: block
    }

    body #results-top-box {
        height: auto;
    }

    body #loader {
        padding: 25px;
    }
}

/* < Tablets landscape */
@media (max-width: 835px) {

    body header.flex-item {
        flex-direction: row;
        justify-content: space-between;
        height: 80px;
    }

    body header .header-link {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    body header .header-link h4 {
        margin-bottom: 0;
    }

    body .hide-835 {
        display: none;
    }

    /* Concept */
    body .concept-box {
        width: 95%;
    }

    body .concept-impact-box {
        padding: 0 2em
    }

    body #concept .concept-img-box img {
        width: 100%;
    }

    /* A propos */
    body .about-card {
        width: 95%;
    }

    body .dev-team {
        width: 100%;
    }

    body #about-law-box {
        flex-direction: column;
    }

    body #about-law-box>div {
        width: 100%;
        margin-top: 25px;
    }

    body iframe {
        width: 100vw;
        max-width: 100%;
        height: 50.5vw;
    }

    /* Contact */
    body .contact-title,
    body #contact-form p {
        width: 100%;
    }

    body #contact-form {
        justify-content: space-between;
    }

    body #contact-form>div {
        width: 46%;
    }

    body #contact-form>div:first-of-type {
        width: 48%;
        margin-right: 0;
    }

    /* Contribuer */
    body .contribuer {
        width: 90%;
    }

    body .concept-box h5 {
        font-size: 1em;
    }

    body .sim-page {
        flex-direction: column;
    }

    body .sim-page>* {
        width: 100%;
    }

    body .sim-page {
        height: auto;

    }

    body #results-top-box {
        margin-top: 10px;
    }

    body #results-emissions {
        background-color: #FFF;
        padding: 15px 0;
        margin-top: 15px;
    }

    body #results-button {
        margin-top: 15px;
    }

    body .res-chart,
    body #res-impacts .res-chart,
    body #res-impacts .res-chart-infos,
    body .res-chart-infos {
        width: 100%;
    }

    body .res-chart-container {
        flex-direction: column;
    }

    body .res-chart {
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }

    body .res-chart-legend>div {
        width: 50%;
        padding: 5px;
        margin-right: 0;
    }
}

/* Mobile */
@media (max-width: 600px) {
    body h3 {
        font-size: 1.5em;
    }

    .flex-item {
        flex-direction: column;
        width: 100%;
    }

    body .results-page header {
        padding-left: 0;
        padding-right: 0;
    }

    body footer.flex-item {
        height: auto;
        padding: 0;
        width: 85%;
    }

    body footer .footer-item.flex-item {
        flex-direction: row;
    }

    body footer .footer-item.flex-item.footer-logos {
        justify-content: space-between;
        margin-bottom: 15px;
    }

    body .footer-logo {
        height: 30px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    body footer .left-btn:first-of-type {
        margin-left: 0;
    }

    body .hero-left>* {
        width: 100%;
    }

    .hero-left .hero-text {
        margin-bottom: 25px;
    }

    .hero-left .hero-text br {
        display: none;
    }

    .hero-btn {
        z-index: 99;
        position: relative;
        text-align: center;
    }

    #hero-img-container {
        margin-top: -70px;
        position: relative;
        z-index: 1;
    }

    body .hero-landing {
        height: auto;
    }

    body .team-logo {
        margin-bottom: 1em;
        margin-right: 0;
    }

    .hero-box #hero-img-container,
    .hero-box .hero-left,
    body .home-item {
        width: 100%;
    }

    /* Concept */

    body .chapter-selection {
        flex-direction: row;
        padding: 25px;
    }

    body .chapter-selection img {
        margin-bottom: 15px;
        margin-right: 25px;
    }

    body .concept-box {
        width: 85%;
        padding: 2em 0;
    }

    body img.border-btn {
        padding: 0.25em
    }

    .concept-page ul {
        padding: 0;
        list-style-position: inside;
    }

    .concept-page ul li {
        padding-left: 5px;
    }

    .concept-page ul li ul {
        margin-top: 5px;
    }

    #enjeux .concept-img-box {
        display: none;
    }

    body .concept-impact-box {
        padding: 0;
        margin: 1em 0;
    }

    body .concept-impact-box img {
        margin-right: 0;
        margin-bottom: 1em;
    }

    /* About */
    body #about-history {
        margin-bottom: 1em
    }

    body .about-nav.flex-item {
        flex-direction: row;
    }

    body #about-articles.flex-item {
        flex-direction: row;
        flex-wrap: wrap;
    }

    body #about-articles.flex-item .flex-item {
        width: 48%;
        margin: 5px 0;
    }

    body .about-nav>div {
        width: 25%;
    }

    body .about-chapter-selection span {
        font-size: 0.9em;
    }

    body .about-page ul {
        padding: 0;
        list-style-position: inside;
    }

    body .about-page ul li {
        margin-bottom: 10px
    }

    body .contribuer-title h3 {
        font-size: 1.7em;
    }

    body .contribuer>p {
        width: 90%;
        padding: 2em 0;
    }

    body #contribuer-dons {
        width: 90%;
    }

    body #contribuer-dons h5 {
        font-size: 1.5em;
        margin-bottom: 1em;
    }

    .contribuer .home-item+.home-item {
        margin-top: 2em;
    }

    body #contact-email,
    body .contact-white {
        width: 100%;
    }

    body #contact-form>div:first-of-type,
    body #contact-form>div {
        width: 100%;
    }

    body .contact-title {
        margin-top: 1.5em;
    }

    body .contact-title img {
        margin-bottom: 0.5em;
    }

    body .contact-white form>input:first-of-type {
        width: 75%;
        margin-right: 0;
    }

    body .contact-white form {
        width: 100%;
    }

    body .contact-white p {
        margin: 0.5em 0;
    }

    body .contact-white form .valid-button {
        display: block;
        margin: 1em auto 0;
    }

    /* SIMULATION */
    body #sim-nav-box {
        height: auto;
    }


    body .sim-nav-categories {
        flex-direction: row;
    }

    body .sim-nav-category img {
        width: 74%;
    }

    body .sim-categorie>div,
    body .sim-categorie-name.sim-categorie-name {
        width: 100%;
    }

    body .sim-categorie-markers {
        flex-direction: row;
    }

    body .sim-option-form>.flex-item {
        margin: 10px 0 10px
    }

    body #scrollOptions {
        width: 95%;
    }

    body #scrollOptions.sticky {
        margin-top: 15px;
        max-height: 100vh;
        overflow-y: scroll;
    }

    body #sim-nav-box>.flex-item {
        flex-direction: row;
    }

    body .sim-page {
        position: relative;
    }

    body #results-emissions-charts-container,
    body #results-impacts-box .results-legend,
    body #results-impacts-box h1 {
        display: none;
    }

    body #results-emissions {
        margin: 15px 0;
        padding: 10px;
        background-color: #FFF;
    }

    body #results-impacts-box {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background-color: #FFF;
        padding: 5px;
        margin-top: 0;
        border-top: 1px solid #CCC;
    }

    body .result-spacer {
        height: 50px;
    }

    body #results-impacts-box .results-figure {
        height: 1.5rem;
        line-height: 1.5rem;
    }

    body #results-impacts-box .results-title {
        font-size: 0.7em;
    }

    body #results-top-box {
        margin-bottom: 0;
    }

    body .results-page {
        padding: 0 25px;
    }

    body .results-page .chapter-selection {
        padding: 10px 0;
    }

    body .results-page .contact-white,
    body #res-synthese>div,
    body #res-emi-fr,
    body #res-emi-world,
    body #res-impacts {
        width: 100%;
    }

    body #res-synthese-indicator .tag-container {
        margin-bottom: 15px;
    }

    body #res-synthese>div>p {
        margin-left: 0;
        width: 100%;
    }

    body #res-synthese-buttons {
        flex-direction: row;
        flex-wrap: wrap;
    }

    body #hero-article {
        max-width: 100%;
    }

    body .res-emi-fr-container {
        min-height: auto;
    }

    body .res-chart-infos {
        padding-left: 0
    }


    body .res-chart-legend {
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 15px;
    }

    body .recharts-tooltip-wrapper {
        max-width: 100%;
    }

    body #area-tooltip {
        max-width: 85%;
    }

    body #blinking-results {
        padding: 10px;
    }

    body .see-more-btn {
        margin: 5px 0
    }

    body .param-container-expanded {
        grid-column-end: auto;
    }

    body .right-btn {
        margin-right: 0;
    }

    body .param-info-container-visible p {
        width: 100%;
    }
}

/* Tablets portrait + large mobile */
/* @media (min-width: 480px) and (max-width:835px) {
    body {
        transform: rotate(-90deg);
        width: 100vh;
        height: 100vw;
    }
} */
/* /// FONT */

.bold-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.light-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

/* /// DEBUG */
* {
    /* border: 1px solid red; */
    margin-bottom: 0;
}

#mobile-message {
    visibility: hidden;
}

/* /// COLORS */
:root {
    /* /// BACKGROUND */
    --backpurple: linear-gradient(315deg, #452e5a 0%, #271d46 50%, #001438 100%);
    --backgreen: linear-gradient(135deg, rgb(0, 124, 161), rgb(126, 212, 164));
    --backwhite: rgb(250, 255, 250, 0.8);

    /* /// CHART */
    --darkblue: #012340;
    --darkpurple: #312559;
    --purple: #8B4F8C;
    --green: #77D9B5;
    --lightgreen: #88F2B6;
    --grey: rgb(228, 228, 228);
    --darkgrey: darkgrey;

    /* /// TEMP DATA */
    --tempgreen: rgb(56, 177, 126);
    --tempyellowgreen: rgb(163, 207, 91);
    --tempyellow: rgb(255, 230, 0);
    --tempyelloworange: rgb(255, 153, 0);
    --temporangered: rgb(255, 94, 0);
    --tempred: crimson;
    --tempredblack: rgb(119, 2, 2);
}

/* /// APP SETUP */
#root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    min-width: 1024px;
    max-width: 1400px;
}

html {
    scroll-behavior: smooth;
}

body {
    background-image: linear-gradient(315deg, #452e5a 0%, #271d46 50%, #001438 100%);
    background-image: var(--backpurple);
    background-attachment: fixed;
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

#content-main {
    width: 100%;
    height: 100vh;
    margin: 0;
}

.light {
    color: #012340;
    color: var(--darkblue);
}

.border {
    border-bottom: 2px solid #77D9B5;
    border-bottom: 2px solid var(--green);
    border-image-slice: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

/* /// GLOBAL */
.hidden {
    visibility: hidden;
}

.displaynone {
    display: none;
}

.flex-item {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.grid-item {
    display: grid;
}

h1 {
    font-size: 5em;
    margin-bottom: 0.2em;
    line-height: 5rem;
}

h2 {
    font-size: 3em;
    margin-bottom: 0.2em;
    line-height: 3rem;
}

h3 {
    font-size: 2em;
    margin-bottom: 0.2em;
    line-height: 2rem;
}

h4 {
    font-size: 1.5em;
    margin-bottom: 0.2em;
    line-height: 1.5rem;
}

.nopad {
    padding: 0;
}

.nomarge {
    margin: 0;
}

.margeup {
    padding-top: 1rem !important;
}

@media (max-width: 800px) and (orientation: portrait) {
    /*     body {
        transform: rotate(-90deg);
        width: 100vh;
        height: 100vw;
    }

    #root {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        align-self: center;
    } */
}



/* cas mobile / largeurs trop petits : affichage d'un message */

#mobile-message {
    visibility: visible;
    width: 100vh;
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

#mobile-message>div {
    border: solid 5px white;
    width: 60vw;
    padding: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

#mobile-message p {
    font-size: 18px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}
*,*::before,*::after,div,header,footer,section,article,main{box-sizing:border-box;margin:0;padding:0}
h1,h2,h3{padding-bottom: 0.2em}
body{min-height:100vh;min-width:100vw;scroll-behavior:smooth;text-rendering:optimizeSpeed;line-height:1.5}
ul[class],ol[class]{list-style:none}
input,button,textarea,select{outline:none}
button{cursor:pointer;}
a{text-decoration:none;color:inherit;}

@media(prefers-reduced-motion:reduce){*{-webkit-animation-duration:.01ms !important;animation-duration:.01ms !important;-webkit-animation-iteration-count:1 !important;animation-iteration-count:1 !important;transition-duration:.01ms !important;scroll-behavior:auto !important}}

body {
  margin: 0;
  font-family: 'Circular Std';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
    padding: 0em 1em;
    height: 8vh;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

header button {
    font-family: 'Open Sans';
    margin-left: 1em;
    border: none;
    font-size: 1.1em;
    font-weight: 500;
}

nav a:nth-child(4) button {
    background-color: #7fffd4;
    border-radius: 5px;
    color: #021438 !important;
    font-weight: 500;
    background-image: var(--backgreen);
    background-size: 120% 120%;
    background-position: center;
    border: 2px solid transparent;
}

#header-simulator {
    color: #163e59;
    background-color: white;
}

.header-left {
    font-family: 'Fugaz One', cursive;
}

.header-link {
    justify-content: flex-start;
    align-items: center;
}

.header-logo {
    width: 2em;
    margin-right: 1em;
}

.header h4 {
    display: inline-block;
}

header .MuiPaper-root {
    background-image: var(--backpurple);
    border-left: 1px solid #555
}

.nav-responsive {
    width: 50px;
}

@media (min-width: 800px) and (max-width: 950px) {

    header button {
        font-family: 'Circular Std';
        margin-left: 0.5em;
        border: none;
        font-size: 0.8em;
    }

}

@media (min-width: 950px) and (max-width: 1100px) {

    header button {
        font-family: 'Circular Std';
        margin-left: 0.7em;
        border: none;
        font-size: 0.9em;
    }

}
.home-page{
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.home-page p {
    font-size: 1.1em;
}

/* /// HERO LANDING */
.hero-landing{
    justify-content: space-between;
    align-items: center;
    min-height: 92vh;
}

.hero-box{
    justify-content: space-evenly;
    align-items: center;
    width: 85%;
}

.hero-left{
    width: 55%;
}

.hero-mission{
    font-size: 4rem;
    line-height: 4rem;
    font-family: 'Fugaz One', cursive;
}

.hero-temp{
    font-size: 6rem;
    line-height: 6rem;
    font-family: 'Fugaz One', cursive;
    background: var(--backgreen);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text{
    width: 90%;
    margin-bottom: 2em;
    text-align: left;
}

.hero-text+.bold-text {
    text-align: right;
}

#hero-img-container {
    width: 45%;
    display: flex;
    justify-content: center;
}

.hero-img{
    width: 90%;
    height: min-intrinsic;
}

.hero-btn{
    width: 90%;
    justify-content: flex-end;
    color: #021438;
}

/* /// NEXT LANDING */
.next-landing{
    padding: 2% 0 10%;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin: 0;
}

.next-landing p {
    margin-bottom: 0.5em;
    text-align: justify;
}

.home-item{
    width: 45%;
    align-items: flex-start;
}

.home-card-head {
    align-items: center;
    margin-bottom: 1em;
  }

.next-landing h4 {
    color: #7fffd4;
  }

/* BLINKING BTN */
.blinking{
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
    margin-bottom: 2rem;
    color: var(--lightgreen);
    font-size: 2em;
    border: none;
}

@-webkit-keyframes blinker {
    50% {
      opacity: 0;
    }
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}

@media (max-width: 800px) and (orientation: portrait) {
    .hero-landing{
        height: 92vw;
    }
}

@media screen and (max-width: 1110px){
    
    .hero-left{
        width: 65%;
    }

    h1 {
        line-height: 3rem;
        margin-bottom: 0.3em;
    }
    
    .hero-temp{
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .hero-mission{
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    
    .hero-text{
        width: 95%;
        margin-bottom: 1em;
    }

    #hero-img-container {
        width: 35%;
    }
    
    .hero-img{
        width: 95%;
    }

    .home-page p {
        font-size: 1em;
    }

    #cta-btn {
        font-size: 1.1em;
    }


  }

@media (min-height: 1100px) {

    .hero-landing{
        height: auto;
        margin: 10vh 0;
    }

    .next-landing{
        height: auto;
        margin-bottom: 10vh;
    }

}
footer{
    padding: 0 2rem;
    height: 10vh;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.footer-item{
    min-width: 30%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
}

.footer-logo{
    height: 38px;
    width: auto;
    margin-right: 1em;
    -webkit-filter: grayscale(100%) contrast(200%);
            filter: grayscale(100%) contrast(200%);
}
.about-page {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2em;
}

.about-page h3 {
  color: #7fffd4;
}

.about-page b {
  font-weight: 600;
}

.about-page h5 {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #7fffd4;
}

.about-page p {
  font-weight: 300;
}

.about-card {
  width: 80%;
  padding: 1em;
  margin-bottom: 3em;
  justify-content: space-between;
}

#about-articles {
  width: 100%;
  padding: 15px 3%;
  justify-content: space-between;
  align-items: flex-end;
}

#about-articles > div {
  align-items: center;
  margin: 0 5px;
}

#about-articles img {
  width: 50px;
  max-height: 50px;
}

#about-articles p {
  text-align: center;
  font-size: 0.9em;
}

iframe{
  align-self: center;
  margin: 1em 0 0;
}

.frame-legend{
  color: rgba(255, 255, 255, 0.8);
  align-self: center;
  font-style: italic;
  padding-bottom: 1em;
}

.separator {
  margin-bottom: 35px;
}

.about-team-section {
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  margin: 0.3em 0 0.5em;
}

.about-card-lead > p:last-of-type {
  margin-bottom: 15px;
}

#about-team p {
  margin: 0.1em 0;
}

.bl-logo {
  width: 80px;
}

.dev-box-img{
  grid-area: img;
}

.dev-box-name{
  grid-area: name;
  align-self: center;
  font-size: 1.2em;
}

.dev-box-desc{
  grid-area: desc;
}

.dev-box-role{
  grid-area: role;
}

.dev-box-logo{
  height: 1.2rem;
  width: auto;
  margin-left: 0.5em;
}

.dev-team {
  margin-bottom: 1em;
  margin-right: 2%;
  width: 35%;
  display: grid;
  grid: 2em auto auto / 40px auto;
  grid-template-areas:
  "img name"
  ". desc"
  ". role";
}

.dev-team > p:first-of-type {
  font-weight: 600;
}

.about-card a {
  color: #7fffd4;
}

.linkedin {
  width: 30px;
  margin: 0;
}

.about-card-head {
  align-items: center;
  margin-bottom: 1em;
}

.about-icon {
  font-size: 2em;
  margin-right: 1rem;
  color: #7fffd4;
}

.team-logo {
  height: 3em;
  width: auto;
  margin-right: 1em;
}

.about-card p {
  margin: 0.5em 0;
  text-align: justify;
}

.about-links {
  justify-content: space-between;
  margin-top: 1em;
  color: var(--green);
  font-weight: bold;
}

.about-links > div > a:first-of-type {
  margin-right: 1em;
}

ul {
  padding: 0 3em;
}

ul > li > span {
  font-weight: bold;
  color: var(--green);
}

.about-img{
  width: 40%;
  height: auto;
  margin-bottom: 3em;
}

.between{
  justify-content: space-evenly;
}

.link-team-about, .link-team-about>img{
  line-height: 1em;
  font-size: 1em;
  height: 1em;
  margin-top: 0.5em;
}

.nav-about-img{
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin: 0 0 20px 0;
}

.about-nav {
  justify-content: center;
}

.about-chapter-selection {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 25px 50px;
  cursor: pointer;
  align-items: center;
}

.about-chapter-selection span {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 5px solid transparent;
  transition: 0.5s;
}

.about-chapter-selection.active span {
  border-bottom: 5px solid #7fffd4;
}

.about-chapter-selection img {
  color: white;
  height: 50px;
  margin-bottom: 5px;
}

.about-chapter-selection svg {
  color: white;
  height: 50px;
  margin-bottom: 5px;
}

.about-chapter-selection:hover span {
  color:#7fffd4;
  border-bottom: 5px solid #7fffd4;
}

#about-law-box {
  widows: 100%; 
  display: flex;
  justify-content: space-between;
}

#about-law-box > div {
  width: 45%;
}


@media screen and (max-width: 1100px){

  .about-nav > div {
    width: 20%;
  }

  .about-chapter-selection {
    padding: 20px 25px;
  }

  .dev-team{
    width: 40%;
  }
}

.form-page {
    align-items: center;
}

.contribuer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Circular Std';
    padding-bottom: 10vh;
}

.contribuer-title {
    margin: 5vh 0 0;
    align-items: center;
}

.contribuer-title h5 {
    color:white;
    font-size: 1.3em;
}

.contribuer-title h3 {
    color:#7fffd4;
    text-align: center;
}

.contribuer > p {
    font-size: 1.2em;
    text-align: justify;
    padding: 2em 3em;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

#contribuer-dons {
    width: 100%;
    width: 70%;
    background-color: white;
    color: #012340;
    margin-bottom: 3em;
    padding: 1.5em;
    display:flex;
    flex-direction: column;
    align-items: center;

}

#contribuer-dons h5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3em;
}

#contribuer-dons p {
    font-size: 1.2em;
    margin: 0.2em 0 0.8em;
    text-align: justify;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}



.contribuer > section {
    width: 100%;
    justify-content: space-between;
}

.contribuer > section h4 {
    color:#7fffd4;
}

.contribuer > section h5 {
    color:white;
    margin:1em 0 0.5em;
    font-size: 1.3em;
}

.contribuer > section h4:first-of-type {
    margin-top:0.5em;
}

.contribuer > section p {
    font-weight: 300;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
}


/* spécificités page contact */



#contact-form {
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
}

#contact-form > div:first-of-type {
    width: 45%;
    margin-right: 10%;
}

#contact-form p{
    width: 300px;
}

.contact-title {
    color: #7fffd4;
    align-items: center;
    margin: 1em 0 0.5em;
    width: 300px;
}

.contact-title img {
    max-height: 30px;
    margin-right: 10px;
}

.contact-title h6 {
    margin: 0;
    font-size: 1.3em;
}


.contact-white {
    width: 80%;
    background-color: white;
    color: #012340;
    margin: 2em 0;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-email {
    width: 50%;
}

.contact-white h5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3em;
}

.contact-white p {
    font-size: 1em;
    margin: 0.2em 0;
    text-align: justify;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.contact-white form > input:first-of-type {
    width: 250px;
    line-height: 25px;
    margin: 5px 15px 0;
}

.contact-white form > button input {
    background-color: inherit;
    border: none;
    font-weight: bold;
}

@media screen and (max-width: 1000px){

    #contact-form > div:first-of-type {
        width: 40%;
        margin-right: 10%;
    }

  }
.concept-page {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2em;
}

.concept-box {
  width: 80%;
  padding: 2em 2em;
  border-radius: 1em;
  margin-bottom: 3em;
  justify-content: space-between;
}

img.border-btn {
  background: white;
}

.chapter-selection {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 25px 50px;
  cursor: pointer;
  align-items: center;
}

.chapter-selection.active {
  color: #7fffd4;
}

.concept-page > div a {
  color: #7fffd4;
  font-weight: bolder;
}

.chapter-selection span {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 5px solid transparent;
  transition: 0.5s;
}

.chapter-selection.active span {
  border-bottom: 5px solid #7fffd4;
}

.chapter-selection img {
  color: white;
  height: 50px;
  margin-bottom: 20px;
}

.chapter-selection svg {
  color: white;
  height: 50px;
  margin-bottom: 20px;
}

.concept-box p {
  margin: 1em 0;
  text-align: justify;
  font-weight: 300;
  font-size: 1rem;
}

.concept-page h3 {
  color: #7fffd4;
}

.concept-page ul, li {
  font-size: 1rem;
}

.concept-box h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #7fffd4;
  font-size: 1.2em;
}

.question h5{
  font-style: italic;
}

.concept-img-box {
  padding: 2em 0;
  justify-content: center;
}

#concept .concept-img-box img {
  width: 50%;
  height: min-intrinsic;
}

#enjeux .concept-img-box img {
  width: 75%;
  height: min-intrinsic;
}

img.border-btn {
  border-radius: 1em;
  padding: 1em 2em;
}

.concept-impact-box {
  padding: 0 6em;
  margin: 2em 0;
  align-items: center;
}

.concept-impact-box img {
  margin-right: 2em;
  width: 5em;
  height: auto;
}

.param-container-normal {
    width: 100%;
    padding: 0 2em;
}

.param-container-expanded{
    width: 100%;
    padding: 0 2em;
    grid-column-end: span 2;
}

.param-header {
    justify-content: space-between;
    align-items: center;
}

.mode-expert h6{
    color:#512072;
}

.param-name {
    margin-bottom: 2px;
    font-size: 15px;
    line-height: 19px;
}

.small-param-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 115.9%;
    letter-spacing: -0.015em;
    margin-bottom: 2px;
}

.param-info-container-hidden {
    display: none;
    opacity: 0;
}

.param-info-container-visible {
    /* background-color: var(--grey); */
    padding: 1em;
    border-radius: 1em;
    width: 100%;
}

.param-info-container-visible h6{
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 10px
}

.param-info-container-visible p{
    font-size: 0.7em;
    margin-bottom: 20px;
    width: 90%;
}

.modal-parent {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 50%);
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    box-shadow: grey;
    border-radius: 15px;
    padding: 20px 20px;
    max-height: 90vh;
  }
  
  .close-btn {
    align-self: flex-end;
    color:black;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 22px;
  }

#modal .ok-btn {
  border-radius: 0;
  width:100px;
  padding:10px 5px;
  font-size: 16px;
  background-color: white;
  color:black;
  border:black solid 2px;
  align-self: flex-end;
  font-weight: 600;
  text-align: center;
}

#modal .ok-btn:hover {background-color: black;color:white}
  
#ateliers_statistiques {
    background-color: #e5e5e5;
    color: #163E59;
    width: 100%;
    padding : 10px;
}
#ateliers_intro {
    width: 100%;
    padding: 30px 20px;
    background-color: white;
    margin-bottom: 10px;
}


#ateliers_intro h1 {font-size: 3em;}
#ateliers_intro button {font-size: 20px; margin-top:20px; margin-right: 20px;}

#ateliers_statistiques h3 {padding: 20px 0}
#ateliers_statistiques .span_underline {width: 300px;height: 8px; margin-top:-10px;margin-bottom: 40px;}

.atelier_cat {
    width: 100%;
}

.atelier_param {
    width: 100%;
    padding: 30px 20px;
    background-color: white;
    margin-bottom: 10px;
}

.indicator_container {display: flex;width:100%;margin: 30px 0;}
.indicator_box {display: flex;flex-direction: column;margin-right: 20px; align-items: center;}

.indicator_box .results-figure {display: flex;background-color: rgb(176, 224, 230);}
.indicator_box:first-child .results-figure {background-color: rgb(233, 231, 236);}
.indicator_box:nth-child(2) .results-figure {background-color: rgb(233, 231, 236);}
.indicator_box:nth-child(3) .results-figure {background-color: rgb(64, 224, 208);}
.indicator_box:nth-child(4) .results-figure {background-color: rgb(176, 224, 230);}
.indicator_box:nth-child(5) .results-figure {background-color: rgb(176, 224, 230);}

.bar_graph {
    width: 90%;
    height: 300px;
}
