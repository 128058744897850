.about-page {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2em;
}

.about-page h3 {
  color: #7fffd4;
}

.about-page b {
  font-weight: 600;
}

.about-page h5 {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #7fffd4;
}

.about-page p {
  font-weight: 300;
}

.about-card {
  width: 80%;
  padding: 1em;
  margin-bottom: 3em;
  justify-content: space-between;
}

#about-articles {
  width: 100%;
  padding: 15px 3%;
  justify-content: space-between;
  align-items: flex-end;
}

#about-articles > div {
  align-items: center;
  margin: 0 5px;
}

#about-articles img {
  width: 50px;
  max-height: 50px;
}

#about-articles p {
  text-align: center;
  font-size: 0.9em;
}

iframe{
  align-self: center;
  margin: 1em 0 0;
}

.frame-legend{
  color: rgba(255, 255, 255, 0.8);
  align-self: center;
  font-style: italic;
  padding-bottom: 1em;
}

.separator {
  margin-bottom: 35px;
}

.about-team-section {
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  margin: 0.3em 0 0.5em;
}

.about-card-lead > p:last-of-type {
  margin-bottom: 15px;
}

#about-team p {
  margin: 0.1em 0;
}

.bl-logo {
  width: 80px;
}

.dev-box-img{
  grid-area: img;
}

.dev-box-name{
  grid-area: name;
  align-self: center;
  font-size: 1.2em;
}

.dev-box-desc{
  grid-area: desc;
}

.dev-box-role{
  grid-area: role;
}

.dev-box-logo{
  height: 1.2rem;
  width: auto;
  margin-left: 0.5em;
}

.dev-team {
  margin-bottom: 1em;
  margin-right: 2%;
  width: 35%;
  display: grid;
  grid: 2em auto auto / 40px auto;
  grid-template-areas:
  "img name"
  ". desc"
  ". role";
}

.dev-team > p:first-of-type {
  font-weight: 600;
}

.about-card a {
  color: #7fffd4;
}

.linkedin {
  width: 30px;
  margin: 0;
}

.about-card-head {
  align-items: center;
  margin-bottom: 1em;
}

.about-icon {
  font-size: 2em;
  margin-right: 1rem;
  color: #7fffd4;
}

.team-logo {
  height: 3em;
  width: auto;
  margin-right: 1em;
}

.about-card p {
  margin: 0.5em 0;
  text-align: justify;
}

.about-links {
  justify-content: space-between;
  margin-top: 1em;
  color: var(--green);
  font-weight: bold;
}

.about-links > div > a:first-of-type {
  margin-right: 1em;
}

ul {
  padding: 0 3em;
}

ul > li > span {
  font-weight: bold;
  color: var(--green);
}

.about-img{
  width: 40%;
  height: auto;
  margin-bottom: 3em;
}

.between{
  justify-content: space-evenly;
}

.link-team-about, .link-team-about>img{
  line-height: 1em;
  font-size: 1em;
  height: 1em;
  margin-top: 0.5em;
}

.nav-about-img{
  filter: grayscale(100%);
  margin: 0 0 20px 0;
}

.about-nav {
  justify-content: center;
}

.about-chapter-selection {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 25px 50px;
  cursor: pointer;
  align-items: center;
}

.about-chapter-selection span {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 5px solid transparent;
  transition: 0.5s;
}

.about-chapter-selection.active span {
  border-bottom: 5px solid #7fffd4;
}

.about-chapter-selection img {
  color: white;
  height: 50px;
  margin-bottom: 5px;
}

.about-chapter-selection svg {
  color: white;
  height: 50px;
  margin-bottom: 5px;
}

.about-chapter-selection:hover span {
  color:#7fffd4;
  border-bottom: 5px solid #7fffd4;
}

#about-law-box {
  widows: 100%; 
  display: flex;
  justify-content: space-between;
}

#about-law-box > div {
  width: 45%;
}


@media screen and (max-width: 1100px){

  .about-nav > div {
    width: 20%;
  }

  .about-chapter-selection {
    padding: 20px 25px;
  }

  .dev-team{
    width: 40%;
  }
}
