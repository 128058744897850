.concept-page {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2em;
}

.concept-box {
  width: 80%;
  padding: 2em 2em;
  border-radius: 1em;
  margin-bottom: 3em;
  justify-content: space-between;
}

img.border-btn {
  background: white;
}

.chapter-selection {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 25px 50px;
  cursor: pointer;
  align-items: center;
}

.chapter-selection.active {
  color: #7fffd4;
}

.concept-page > div a {
  color: #7fffd4;
  font-weight: bolder;
}

.chapter-selection span {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 5px solid transparent;
  transition: 0.5s;
}

.chapter-selection.active span {
  border-bottom: 5px solid #7fffd4;
}

.chapter-selection img {
  color: white;
  height: 50px;
  margin-bottom: 20px;
}

.chapter-selection svg {
  color: white;
  height: 50px;
  margin-bottom: 20px;
}

.concept-box p {
  margin: 1em 0;
  text-align: justify;
  font-weight: 300;
  font-size: 1rem;
}

.concept-page h3 {
  color: #7fffd4;
}

.concept-page ul, li {
  font-size: 1rem;
}

.concept-box h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #7fffd4;
  font-size: 1.2em;
}

.question h5{
  font-style: italic;
}

.concept-img-box {
  padding: 2em 0;
  justify-content: center;
}

#concept .concept-img-box img {
  width: 50%;
  height: min-intrinsic;
}

#enjeux .concept-img-box img {
  width: 75%;
  height: min-intrinsic;
}

img.border-btn {
  border-radius: 1em;
  padding: 1em 2em;
}

.concept-impact-box {
  padding: 0 6em;
  margin: 2em 0;
  align-items: center;
}

.concept-impact-box img {
  margin-right: 2em;
  width: 5em;
  height: auto;
}
